import React from 'react';

const ActionButton = ({ id, type = 'button', className, onClick, isLoading, disabled, content }) => {
  let buttonClassName = 'buttonV2';
  if (className) {
    buttonClassName += ` ${className}`;
  }

  if (isLoading) {
    return (
      <button id={id} className={`button_loaderV2 ${buttonClassName}`}>
        <div className="button_loader__elementsV2" />
      </button>
    );
  } else {
    if (disabled) {
      buttonClassName += ' disabled';
    }

    return (
      <button id={id} type={type} className={buttonClassName} disabled={disabled} onClick={onClick}>
        {content}
      </button>
    );
  }
};

export default ActionButton;
