import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'Components/Base/Icon'

const RadioButtonListItem = ({ name, isSelected, onClick }) => {
  const classes = `radio-button-list__option ${isSelected ? 'radio-button-list__option--selected' : ''}`
  const iconName = isSelected ? 'circle-selected' : 'circle-unselected'

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' || key === ' ') {
      onClick();
    }
  };

  return (
    <div className={classes} onClick={onClick}>
      <div className="row radio-button-list__option--focus-visible" tabIndex={0} onKeyDown={handleKeyDown}>
        <div className="row__col--fixed radio-button-list__radio-button">
          <Icon name={iconName} />
        </div>

        <div className="row__col text-truncate">{name}</div>
      </div>
    </div>
  )
}

RadioButtonListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default RadioButtonListItem
