import React, { Component } from 'react'
import _ from 'lodash'
import { t } from 'i18n'
import PropTypes from 'prop-types'

import GenericScaleQuestionOption from './GenericScaleQuestionOption'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

class GenericScaleQuestion extends Component {
  constructor(props) {
    super(props)
    this.renderOption = this.renderOption.bind(this)
    this.buildQuestionOptions = this.buildQuestionOptions.bind(this)
    this.updateOptions = this.updateOptions.bind(this)

    let questionOptions = props.checkInQuestionOptions || []
    const count = this.props.optionCount

    if (_.isEmpty(questionOptions)) {
      questionOptions = this.buildQuestionOptions(count)
      this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
    }

    this.state = {
      errors: {},
      questionOptions,
    }
  }

  buildQuestionOptions(count) {
    const config = {
      11: {startValue: 0, maxValue: 11, step: 1},
      100: {startValue: 0, maxValue: 101, step: 10},
      default: {startValue: 1, maxValue: count + 1, step: 1},
    };

    const { startValue, maxValue, step } = config[count] || config.default;

    const _this = this
    return _.map(_.range(startValue, maxValue, step), (index) => {
      return _this.buildBlankOption(index - 1)
    });
  }

  buildBlankOption(index) {
    return {
      type: 'ScaleBasedOption',
      data: {
        text: '',
        description: '',
        response: t('default_response', i18nOpts),
        index,
        order: index,
        score: index + 1,
      },
    }
  }

  updateOptions(optionValues) {
    const index = optionValues.index
    const { questionOptions } = this.state

    const questionOption = _.find(questionOptions, (q) => {
      return q.data.index === index
    })

    Object.assign(questionOption.data, optionValues)
    this.setState({ questionOptions })
    this.props.onChange({ target: { name: 'checkInQuestionOptions', value: questionOptions } })
  }

  renderOption(questionOption, index) {
    return (
      <GenericScaleQuestionOption
        isActive={this.props.isActive}
        key={index}
        questionOption={questionOption}
        updateOptions={this.updateOptions}
        renderConditionalResponse={this.props.renderConditionalResponse}
      />
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.optionCount !== prevProps.optionCount) {
      const questionOptions = this.buildQuestionOptions(this.props.optionCount)
      this.setState({ questionOptions })
    }
  }

  render() {
    const { questionOptions } = this.state
    return questionOptions.map(this.renderOption)
  }
}

GenericScaleQuestion.propTypes = {
  checkInQuestionOptions: PropTypes.array,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  optionCount: PropTypes.number.isRequired,
  renderConditionalResponse: PropTypes.func.isRequired,
}

GenericScaleQuestion.defaultProps = {
  checkInQuestionOptions: [],
  isActive: false,
  onChange: null,
}

export default GenericScaleQuestion
