import React, { Component, Fragment } from 'react'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'

import InputGroup from 'Components/Forms/InputGroup'
import Checkbox from 'Components/Forms/Checkbox'
import { Button, Icon } from 'Components/Base'
import { Footer } from 'Components/Base/Card/'
import CheckInQuestionApi from 'Api/TemplateCreator/CheckInQuestion'
import PainScaleQuestion from './PainScaleQuestion/index'
import GenericScaleQuestion from './GenericScaleQuestion/index'
import NumericInputQuestion from './NumericInputQuestion/index'
import MoodScaleQuestion from './MoodScaleQuestion/index'
import YesNoQuestion from './YesNoQuestion/index'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import FreeTextQuestion from './FreeTextQuestion'

const i18nOpts = { scope: 'care_plan_creators.check_in_templates.questions' }

const questionTypes = [
  { value: '', label: t('blank', i18nOpts) },
  { value: 'pain_scale_5', label: t('pain_scale_5', i18nOpts), hasMultipleOptions: true },
  { value: 'generic_scale_5', label: t('generic_scale_5', i18nOpts), hasMultipleOptions: true },
  { value: 'generic_scale_10', label: t('generic_scale_10', i18nOpts), hasMultipleOptions: true },
  { value: 'generic_scale_11', label: t('generic_scale_11', i18nOpts), hasMultipleOptions: true },
  { value: 'generic_scale_0_100', label: t('generic_scale_0_100', i18nOpts), hasMultipleOptions: true },
  { value: 'numeric_input', label: t('numeric_input', i18nOpts), hasMultipleOptions: false },
  { value: 'yes_no', label: t('yes_no', i18nOpts), hasMultipleOptions: false },
  { value: 'mood_scale_10', label: t('mood_scale_10', i18nOpts), hasMultipleOptions: true },
  { value: 'multiple_choice', label: t('multiple_choice', i18nOpts), hasMultipleOptions: true },
  { value: 'free_text', label: t('free_text', i18nOpts), hasMultipleOptions: false },
]

class QuestionsSection extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this)
    this.handleAddQuestion = this.handleAddQuestion.bind(this)

    this.registerQuestion = this.registerQuestion.bind(this)
    this.renderQuestionTypeOptions = this.renderQuestionTypeOptions.bind(this)
    this.renderConditionalResponse = this.renderConditionalResponse.bind(this)
    this.toggleConditionalResponses = this.toggleConditionalResponses.bind(this)

    const checkInQuestions = !_.isEmpty(props.checkInQuestions) ? props.checkInQuestions : [{}]
    const checkInQuestion = checkInQuestions[0]

    this.state = {
      index: 0,
      id: checkInQuestion.id || null,
      checkInQuestions,
      checkInQuestion,
      questionType: checkInQuestion.questionType,
      hasMultipleOptions: checkInQuestion.hasConditionalResponses,
      text: checkInQuestion.text,
      hasConditionalResponses: checkInQuestion.hasConditionalResponses,
      response: checkInQuestion.response || t('default_response', i18nOpts),
      isSubmitting: false,
      checkInQuestionOptions: checkInQuestion.checkInQuestionOptions,
      errors: {},
    }
  }

  get editing() {
    return !!this.state.id
  }

  get api() {
    return new CheckInQuestionApi(this.props.resourceId, this.state.id)
  }

  get questionOptionsParams() {
    const options = {}
    const checkInQuestionOptions = this.state.checkInQuestionOptions || []

    checkInQuestionOptions.map((option, index) => {
      options[index] = { ...option.data, id: option.id, type: option.type }
    })
    return options
  }

  get questionParams() {
    return {
      check_in_question: {
        questionType: this.state.questionType,
        text: this.state.text,
        response: this.state.response,
        hasConditionalResponses: this.state.hasConditionalResponses,
        checkInQuestionOptionsAttributes: this.questionOptionsParams,
        index: this.state.index,
      },
    }
  }

  get defaultParams() {
    return {
      check_in_question: {
        questionType: '',
        text: '',
        response: t('default_response', i18nOpts),
        hasConditionalResponses: false,
        checkInQuestionOptionsAttributes: null,
        index: null,
      },
    }
  }

  handleAddQuestion() {
    let updatedQuestions
    const {
      index,
      text,
      questionType,
      response,
      hasConditionalResponses,
      checkInQuestions,
      id,
      checkInQuestionOptions,
    } = this.state

    const updatedQuestion = {
      id: id || null,
      checkInQuestionOptions,
      questionType,
      text,
      response,
      hasConditionalResponses,
    }

    if (index !== null) {
      updatedQuestions = [
        ...checkInQuestions.slice(0, index),
        updatedQuestion,
        ...checkInQuestions.slice(index + 1),
        this.defaultParams.check_in_question,
      ]
    } else {
      updatedQuestions = [...this.state.checkInQuestions, this.defaultParams.check_in_question]
    }

    this.setState({
      checkInQuestions: updatedQuestions,
      ...this.defaultParams.check_in_question,
      index: this.state.checkInQuestions.length,
      id: null,
    })
  }

  handleSaveQuestion(evt) {
    evt.preventDefault()

    const endpoint = this.editing
      ? this.api.update(this.questionParams)
      : this.api.create(this.questionParams)

    endpoint.then((res) => {
      if (res.ok) {
        res.json().then(this.registerQuestion)
      } else if (res.status === 422) {
        this.setState({ isSubmitting: false })
        res.json().then(this.handleErrors)
      }
    })
  }

  registerQuestion(body) {
    let checkInQuestions

    if (this.editing) {
      checkInQuestions = this.state.checkInQuestions.map((q) => {
        if (q.id === body.question.id) {
          return body.question
        }
        return q
      })
    } else {
      const prevQuestions = this.state.checkInQuestions.slice(0, this.state.index)
      const nextQuestions = this.state.checkInQuestions.slice(this.state.index)
      checkInQuestions = [...prevQuestions, body.question, ...nextQuestions]
      checkInQuestions.splice(this.state.index + 1, 1)
    }

    this.setState({
      checkInQuestions,
      checkInQuestion: {},
      isSubmitting: false,
      errors: {},
      questionType: '',
      text: '',
      response: t('default_response', i18nOpts),
      hasConditionalResponses: false,
      checkInQuestionOptions: [],
      id: null,
      index: null,
    })
  }

  handleChange({ target: { name, value } }) {
    if (name === 'questionType') {
      this.setState({
        checkInQuestionOptions: [],
        hasConditionalResponses: false,
        hasMultipleOptions: questionTypes.find((el) => el.value === value).hasMultipleOptions,
      })
    }

    this.setState((prevState) => _.set(prevState, name, value))
  }

  toggleConditionalResponses() {
    this.handleChange({
      target: {
        name: 'hasConditionalResponses',
        value: !this.state.hasConditionalResponses,
      },
    })
  }

  handleEdit(selected) {
    const { index, checkInQuestions } = this.state

    const question = _.isEmpty(checkInQuestions[index === selected ? null : selected])
      ? this.defaultParams.check_in_question
      : checkInQuestions[index === selected ? null : selected]

    const updateState = {
      isSubmitting: false,
      errors: {},
      index: index === selected ? null : selected,
      questionType: question.questionType,
      text: question.text,
      response: question.response,
      id: question.id || null,
      hasConditionalResponses: question.hasConditionalResponses,
      hasMultipleOptions: question.hasConditionalResponses,
      checkInQuestionOptions: question.checkInQuestionOptions,
    }

    if (index !== null) {
      const updatedQuestion = {
        id: checkInQuestions[index].id || null,
        checkInQuestionOptions: checkInQuestions[index].checkInQuestionOptions,
        questionType: this.state.questionType,
        text: this.state.text,
        response: this.state.response,
        hasConditionalResponses: this.state.hasConditionalResponses,
      }
      this.setState({
        ...updateState,
        checkInQuestions: [
          ...checkInQuestions.slice(0, index),
          updatedQuestion,
          ...checkInQuestions.slice(index + 1),
        ],
      })
    } else {
      this.setState({ ...updateState })
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()

    window.location.hash = '#visibility'
  }

  handleErrors(errors) {
    this.setState({ errors })
  }

  renderConditionalResponse() {
    if (this.state.hasConditionalResponses) {
      return (response, handleChange) => (
        <div>
          <InputGroup
            component="input"
            name="response"
            placeholder={t('placeholders.custom_response', i18nOpts)}
            value={response}
            onChange={handleChange}
            maxLength={this.props.maxResponse}
            className="option-description"
            type="text"
          />
          <span className="character-count">{this.props.maxResponse - (response || '').length}</span>
        </div>
      )
    } else {
      return () => null
    }
  }

  renderQuestionTypeOptions() {
    const { checkInQuestionOptions, questionType, errors } = this.state

    switch (questionType) {
      case 'pain_scale_5':
        return (
          <PainScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        )
      case 'generic_scale_5':
        return (
          <GenericScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            optionCount={5}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        )
      case 'generic_scale_10':
        return (
          <GenericScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            optionCount={10}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        )
      case 'generic_scale_11':
        return (
          <GenericScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            optionCount={11}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        )
      case 'generic_scale_0_100':
        return (
          <GenericScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            optionCount={100}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        );
      case 'numeric_input':
        return (
          <NumericInputQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            errors={errors}
            onChange={this.handleChange}
          />
        )
      case 'mood_scale_10':
        return (
          <MoodScaleQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            errors={errors}
            optionCount={11}
            renderConditionalResponse={this.renderConditionalResponse()}
          />
        )
      case 'multiple_choice':
        return (
          <MultipleChoiceQuestion
            checkInQuestionOptions={checkInQuestionOptions}
            onChange={this.handleChange}
            errors={errors}
            renderConditionalResponse={this.renderConditionalResponse()}
            editing={this.editing}
          />
        )
      case 'yes_no':
        return <YesNoQuestion checkInQuestionOptions={checkInQuestionOptions} onChange={this.handleChange} />
      case 'free_text':
        return (
          <FreeTextQuestion checkInQuestionOptions={checkInQuestionOptions} onChange={this.handleChange} />
        )
      default:
        return null
    }
  }

  renderForm() {
    const {
      questionType,
      text,
      response,
      errors,
      hasMultipleOptions,
      isSubmitting,
      hasConditionalResponses,
    } = this.state
    const { isActive } = this.props

    return (
      <form noValidate>
        <div className="input string required care_plan_title">
          <label className="string required careplan-wizard__details-title" htmlFor="questionType">
            <abbr title="required">*</abbr> {t('question_type', i18nOpts)}
          </label>

          <InputGroup
            component="select"
            name="questionType"
            onChange={this.handleChange}
            value={questionType}
            errors={errors}
            disabled={!_.isNull(this.state.id) || isActive}
            required
          >
            {questionTypes.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </InputGroup>
        </div>

        <div>
          <div className="input string required care_plan_title">
            <label className="string required careplan-wizard__details-title" htmlFor="text">
              <abbr title="required">*</abbr> {t('title_label', i18nOpts)}
            </label>
          </div>
          <InputGroup
            component="input"
            name="text"
            onChange={this.handleChange}
            value={text}
            errors={errors}
            required
            type="text"
          />

          <div className="input string required care_plan_title">
            <label className="string careplan-wizard__details-title" htmlFor="text">
              {t('response_label', i18nOpts)}
            </label>
          </div>

          {hasMultipleOptions && (
            <Checkbox
              name="hasConditionalResponses"
              text={t('conditional_response', i18nOpts)}
              checked={hasConditionalResponses}
              onChange={this.toggleConditionalResponses}
            />
          )}

          {!hasConditionalResponses && (
            <InputGroup
              component="input"
              name="response"
              onChange={this.handleChange}
              value={response}
              errors={errors}
              type="text"
            />
          )}
          <div className="check-in-template">{this.renderQuestionTypeOptions()}</div>
          <div className="question-option__button">
            <Button
              text={t('save_question', i18nOpts)}
              onClick={this.handleSaveQuestion}
              className="btn btn--secondary"
              disabled={questionType === '' || !questionType || !text || isSubmitting}
            />
          </div>
        </div>
      </form>
    )
  }

  render() {
    const { checkInQuestions } = this.state

    return (
      <Fragment>
        <ul className="question-option">
          {!_.isEmpty(checkInQuestions) ? (
            checkInQuestions.map((item, index) => (
              <li className="question-option__section" key={index}>
                <span className="question-option__header-section">
                  <h3 className="question-option__header-text text-medium">{`Question ${index + 1}`}</h3>
                  <div className="btn--link" onClick={() => this.handleEdit(index)}>
                    <Icon name="pencil-edit_alt" className="card__header-link-icon" />
                    <span>{t('edit', i18nOpts)}</span>
                    <Icon name={'carrot-down'} />
                  </div>
                </span>

                {index === this.state.index ? (
                  this.renderForm()
                ) : (
                  <div className="question-option__header">
                    <h3 className="question-option__header-text">{item.text}</h3>
                  </div>
                )}
              </li>
            ))
          ) : (
            <li>
              <span className="question-option__header-section">
                <h3 className="question-option__header-text text-medium">
                  {`Question ${this.state.index + 1}`}
                </h3>
                <div className="icon icon--large" onClick={() => this.handleEdit(this.state.index)}>
                  <Icon name={'carrot-down'} />
                </div>
              </span>
              {this.renderForm()}
            </li>
          )}
        </ul>
        <Footer
          right={
            <Fragment>
              <Button
                text={t('add_question', i18nOpts)}
                onClick={this.handleAddQuestion}
                className="btn btn--secondary"
                disabled={checkInQuestions.length >= 10}
              />
              <Button
                text={t('next', i18nOpts)}
                onClick={this.handleSubmit}
                disabled={!checkInQuestions.every((q) => q.id)}
              />
            </Fragment>
          }
        />
      </Fragment>
    )
  }
}

QuestionsSection.propTypes = {
  checkInQuestions: PropTypes.array,
  isActive: PropTypes.bool,
  maxResponse: PropTypes.number,
  resourceId: PropTypes.number.isRequired,
}

QuestionsSection.defaultProps = {
  checkInQuestions: [],
  isActive: false,
  maxResponse: 250,
}

export default QuestionsSection
