import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';

import { closeModal } from 'Actions/modal';
import { getModalId } from 'Selectors/modal';

import Icon from './Icon';

export const Modal = ({ isOpen, closeModal: onClose, className, title, children, bodyChildren }) => (
  <ReactModal
    ariaHideApp={false}
    closeTimeoutMS={150}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={false}
    shouldReturnFocusAfterClose={false}
    className={className}
    contentLabel={title}
  >
    <div className="ReactModal__ContentHeader">
      <h3 className="text-truncate text-medium">
        <span>{title}</span>
      </h3>

      <a className="ReactModal__ContentHeaderClose" onClick={onClose}>
        <Icon name="close_v3" />
      </a>
    </div>

    <div className={bodyChildren}>{children}</div>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  bodyChildren: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  trigger: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  className: 'ReactModal__Content',
  bodyChildren: 'ReactModal__ContentBody',
  title: '',
  trigger: '',
};

ReactModal.defaultStyles = {}; // Clear default styles

const mapStateToProps = (state, { trigger }) => ({
  isOpen: getModalId(state) === trigger,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal() {
    dispatch(closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
