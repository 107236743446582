import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n';

import RadioButtonListItem from 'Components/Base/RadioButtonListItem'

const i18nOpts = { scope: 'base.radio_button_list' };

const RadioButtonList = ({ header, onClick, options, selectedOptionId, enableSearch = false }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = enableSearch
    ? options.filter(option =>
        option.name?.toLowerCase().trim().includes(searchTerm?.toLowerCase().trim())
      )
    : options;

  let sortedOptions;
  try {
    sortedOptions = filteredOptions.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    );
  } catch {
    sortedOptions = filteredOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div className="radio-button-list">
      <div className="radio-button-list__header">
        <span>{header}</span>
        {enableSearch && (
          <input
            className="radio-button-list__search"
            type="text"
            placeholder={t('search', i18nOpts)}
            aria-label={t('search_aria', i18nOpts)}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
      </div>

      <div className="radio-button-list__body">
        {sortedOptions.map((option) => (
          <RadioButtonListItem
            key={option.id}
            isSelected={selectedOptionId === option.id}
            onClick={onClick.bind(null, option.id)}
            name={option.name}
          />
        ))}
      </div>
    </div>
  )
}
RadioButtonList.propTypes = {
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selectedOptionId: PropTypes.number,
}

RadioButtonList.defaultProps = {
  options: [],
  selectedOptionId: null,
}

export default RadioButtonList
