import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import Icon from 'Components/Base/Icon'
import ActionButton from 'Components/Base/ActionButton'
import CarePlanTemplatesModal from 'Components/Patient/Index/CarePlanTemplatesModal'

const i18nOpts = { scope: 'patient.index' }

class CarePlanButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openLink(link) {
    try {
      window.open(link, '_self');
    } catch (error) {
      console.error("Navigation error:", error);
    }
  }

  render() {
    const { registrationIsOpen } = this.props;

    return (
      <div
        className={`patients__index-buttons && ${
          registrationIsOpen && 'patients__index-buttons--registration'
        }`}
      >
        <div className="action-header__button-section--flex-row">
          {registrationIsOpen && (
            <ActionButton
              id="invite-patient"
              className="button-flat action-header__button--flex-center"
              content={
                <>
                  <Icon name="link_alt2" />
                  {t('self_signup_link', i18nOpts)}
                </>
              }
            />
          )}
          <ActionButton
            id="add-action-plan"
            content={
              <>
                <Icon name="plus_alt3" />
                {t('add_action_plan', i18nOpts)}
              </>
            }
            onClick={
              this.props.templatesAreAvailable
                ? this.openModal
                : () => this.openLink(this.props.newCarePlanPath)
            }
          />
        </div>

        {this.state.modalIsOpen && (
          <CarePlanTemplatesModal
            closeModal={this.closeModal}
            isOpen={this.state.modalIsOpen}
            newCarePlanPath={this.props.newCarePlanPath}
          />
        )}
      </div>
    );
  }
}

CarePlanButtons.propTypes = {
  newCarePlanPath: PropTypes.string.isRequired,
  registrationIsOpen: PropTypes.bool,
  templatesAreAvailable: PropTypes.bool,
}

CarePlanButtons.defaultProps = {
  registrationIsOpen: false,
  templatesAreAvailable: false,
}

export default CarePlanButtons
