import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import CarePlan from './CarePlan'
import Contact from 'Components/Inbox/SideNav/NewContacts/Contact'
import InputGroup from 'Components/Forms/InputGroup'
import Button from 'Components/Base/Button'

const i18nOpts = { scope: 'inbox.side_nav.channels' }

const selectedContactsCount = (contacts) => contacts.filter((c) => c.isSelected).length

const isDisabled = (errors, channelName, contacts) =>
  !!Object.keys(errors).length || !channelName?.length || !contacts.filter((c) => c.isSelected)?.length

const selectedMembersCount = (count) => t('channel_members', { scope: 'inbox.side_nav.channels', count })

const ChannelName = ({ channelName, contacts, errors, onChange, onClick, onToggle, title }) => (
  <div className="channel">
    <CarePlan title={title} />

    <hr />

    <div className="channel__name">
      <label htmlFor="channelName" className="h3">
        {t('channel_name', i18nOpts)}
      </label>

      <p className="channel__hint text-primary-lighter">{t('channel_name_hint', i18nOpts)}</p>

      <InputGroup
        component="input"
        errors={errors}
        name="channelName"
        onChange={onChange}
        value={channelName}
      />
    </div>

    <hr />

    <div className="channel__confirm-contacts">
      <p htmlFor="channelMembers" className="channel__info">
        {selectedMembersCount(selectedContactsCount(contacts))}
      </p>

      <section className="inbox-menu__contacts">
        <div className="inbox-menu__contacts-scroll">
          {contacts.map(({ id, isSelected, ...rest }) => (
            <Contact
              key={id}
              isSelected={isSelected}
              onClick={onToggle.bind(null, { id, ...rest })}
              {...rest}
            />
          ))}
        </div>
      </section>
    </div>

    <Button
      className="btn channel__btn"
      disabled={isDisabled(errors, channelName, contacts)}
      onClick={onClick}
      text={t('start', i18nOpts)}
      type="submit"
    />
  </div>
)

ChannelName.propTypes = {
  channelName: PropTypes.string,
  contacts: PropTypes.array,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

ChannelName.defaultProps = {
  channelName: '',
  contacts: [],
  errors: {},
}

export default ChannelName
