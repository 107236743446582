import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { Icon } from 'Components/Base'
import ScoreIcon from 'Components/Base/ScoreIcon'

const CheckInScoreSection = ({ type, submittedData, skipped, whiteBackground }) => {
  if (skipped) {
    return (
      <div className="skipped">
        {t('skipped', { scope: 'patient.check_in_result' })}
      </div>
    )
  }

  switch (type) {
    case 'pain_scale_5':
      return (
        <div>
          <div className="score">
            <span className="submitted-score">{submittedData.score}</span>
            <span>{' / 5'}</span>
          </div>
          <div className="pain-scale">
            <div style={{ display: 'inline-block', color: submittedData.colorCode }}>
              <Icon name={submittedData.iconName} className="checkin-icon" />
            </div>
            <span>{submittedData.text}</span>
          </div>
        </div>
      )
    case 'generic_scale_5':
    case 'generic_scale_10':
    case 'generic_scale_11':
    case 'generic_scale_0_100':
    case 'mood_scale_5':
    case 'mood_scale_10':
    case 'multiple_choice':
      return (
        <div className="pain-scale">
          <ScoreIcon
            className="checkin-icon"
            score={submittedData.score}
            background={submittedData.colorCode || whiteBackground}
          />
          <span>{submittedData.text}</span>
        </div>
      )
    case 'numeric_input':
      return (
        <div className="numeric-input-container">
          <div className="input-value numeric-input">{submittedData.userInput}</div>
          <div className="unit numeric-input">{submittedData.userInputUnit}</div>
        </div>
      )
    case 'free_text':
      return (
        <p className="checkin__response-value checkin__response-value--freetext">
          {submittedData.userInput}
        </p>
      )
    case 'yes_no':
      return (
        <p className='checkin__response-value checkin__response-value--bool'>
          {t(submittedData.score, { scope: 'patient.check_in_score' })}
        </p>
      )
    default:
      return null
  }
}

CheckInScoreSection.propTypes = {
  skipped: PropTypes.boolean,
  submittedData: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  whiteBackground: PropTypes.string
}

CheckInScoreSection.defaultProps = {
  skipped: false,
  whiteBackground: '#1085c6',
}

export default CheckInScoreSection
